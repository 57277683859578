import { createApp } from 'vue';
import Header from './components/Header/Header.vue';
import SidebarProjects from './components/Sidebar/SidebarProjects.vue';
import Login from './components/Login/Login.vue';
import Catalogs from './components/Catalogs/Catalogs.vue';
import AddCatalog from './components/Catalogs/AddCatalog.vue';
import EditCatalog from './components/Catalogs/EditCatalog.vue';
import Profile from './components/Profile/Profile.vue';
import Users from './components/Users/Users.vue';
import User from './components/Users/User.vue';
import Projects from './components/Projects/Projects.vue';
import Project from './components/Projects/Project.vue';
import Requests from './components/Requests/Requests.vue';
window.io = require('socket.io-client');
import autosize from 'autosize/dist/autosize';
import PrimeVue from 'primevue/config';

document.addEventListener("DOMContentLoaded", function(event) {
  createApp(Login, {}).mount("#login");
  if (document.getElementById('catalogs')) {
    createApp(Catalogs, {catalogs: JSON.parse(document.getElementById('catalogs').getAttribute('data-catalogs'))})
    .mount("#catalogs");
  }
  if (document.getElementById('add-catalog')) {
    createApp(AddCatalog, {projects: JSON.parse(document.getElementById('add-catalog').getAttribute('data-projects'))})
    .mount("#add-catalog");
  }
  if (document.getElementById('edit-catalog')) {
    createApp(EditCatalog, {projects: JSON.parse(document.getElementById('edit-catalog').getAttribute('data-projects')),
      catalog: JSON.parse(document.getElementById('edit-catalog').getAttribute('data-catalog'))})
    .mount("#edit-catalog");
  }
  if (document.getElementById('profile')) {
    createApp(Profile, {profile: JSON.parse(document.getElementById('profile').getAttribute('data-profile'))})
    .mount("#profile");
  }
  if (document.getElementById('users')) {
    createApp(Users, {users: JSON.parse(document.getElementById('users').getAttribute('data-users'))})
    .mount("#users");
  }
  if (document.getElementById('user')) {
    createApp(User, {
      roles: JSON.parse(document.getElementById('user').getAttribute('data-roles')),
      user: document.getElementById('user').getAttribute('data-user') ? JSON.parse(document.getElementById('user').getAttribute('data-user')) : null,
      setUserAsAdmin: document.getElementById('user').getAttribute('data-set-user-as-admin') ? JSON.parse(document.getElementById('user').getAttribute('data-set-user-as-admin')) : null,
    })
    .mount("#user");
  }
  if (document.getElementById('projects')) {
    createApp(Projects, {projects: JSON.parse(document.getElementById('projects').getAttribute('data-projects'))})
    .mount("#projects");
  }
  if (document.getElementById('project')) {
    createApp(Project, {
      users: JSON.parse(document.getElementById('project').getAttribute('data-users')),
      types: JSON.parse(document.getElementById('project').getAttribute('data-types')),
      usersPermissions: document.getElementById('project').getAttribute('data-users-permissions') ? JSON.parse(document.getElementById('project').getAttribute('data-users-permissions')) : null,
      setUserAsCreator: document.getElementById('project').getAttribute('data-set-user-as-creator') ? JSON.parse(document.getElementById('project').getAttribute('data-set-user-as-creator')) : null,
      project: document.getElementById('project').getAttribute('data-project') ? JSON.parse(document.getElementById('project').getAttribute('data-project')) : null
    })
    .mount("#project");
  }
  if (document.getElementById('requests')) {
    createApp(Requests, {
      viewTypes: JSON.parse(document.getElementById('requests').getAttribute('data-view-types')),
      user: JSON.parse(document.getElementById('requests').getAttribute('data-user')),
      userSettings: JSON.parse(document.getElementById('requests').getAttribute('data-user-settings')),
      project: document.getElementById('requests').getAttribute('data-project') ?  JSON.parse(document.getElementById('requests').getAttribute('data-project')) : null,
    })
    .use(PrimeVue)
    .mount("#requests");
  }
  if (document.getElementById('sidebar-projects')) {
    createApp(SidebarProjects, {
      user: JSON.parse(document.getElementById('sidebar-projects').getAttribute('data-user')),
      data: JSON.parse(document.getElementById('sidebar-projects').getAttribute('data-data'))
    })
    .mount("#sidebar-projects");
  }
  if (document.getElementById('header')) {
    createApp(Header, {user: JSON.parse(document.getElementById('header').getAttribute('data-user'))})
    .use(PrimeVue)
    .mount("#header");
  }
  const phones = document.querySelectorAll(
      '.phone-mask');
  if (phones) {
    phones.forEach((element) => {
      Inputmask({'mask': '+7 (999) 999-9999'}).mask(element);
    });
  }

  const emails = document.querySelectorAll(
      '.email-mask');
  if (emails) {
    emails.forEach((element) => {
      Inputmask({
        mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
        greedy: false,
        onBeforePaste: function (pastedValue, opts) {
          pastedValue = pastedValue.toLowerCase();
          return pastedValue.replace("mailto:", "");
        },
        definitions: {
          '*': {
            validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
            casing: "lower"
          }
        }
      }).mask(element);
    });
  }

  const textareaElements = document.querySelectorAll('.form-field textarea:not(.textarea_vue)');

  if (textareaElements) {
    autosize(textareaElements);
  }
});
